import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import Images from "../../extra/images";
import { animateLeft, animateRight } from "../../extra/utils";
import Stories from "../components/Stories";
import Title from "../components/Title";

import RightIcon from "../../assets/icons/arrow-right.svg";
import GsaleIcon from "../../assets/icons/gsale-3.svg";
import PrevIcon from "../../assets/icons/prev.svg";
import QrIcon from "../../assets/icons/qr-down.svg";
import Step1Icon from "../../assets/icons/step-1.svg";
import Step2Icon from "../../assets/icons/step-2.svg";
import Step3Icon from "../../assets/icons/step-3.svg";
import TickIcon from "../../assets/icons/tick.svg";

const benefits = [
  "Tìm hiểu, so sánh, tùy chọn các sản phẩm bảo hiểm từ các nhà bảo hiểm uy tín trên thị trường.",
  "Tham gia các sản phẩm bảo hiểm với mức giá ưu đãi.",
  "Bán các sản phẩm bảo hiểm thông qua mobile app, hưởng hoa hồng hấp dẫn.",
  "Phát triển đội nhóm, mạng lưới CTV để tăng thu nhập.",
  "Quản lý các giao dịch và doanh thu bảo hiểm.",
];

const steps = [
  {
    icon: Step1Icon,
    title: "Giới thiệu bạn bè",
    text: `Vào “Tài khoản” chọn “Giới thiệu bạn bè”`,
    img: Images.Step1,
  },
  {
    icon: Step2Icon,
    title: "Lan toả trên các kênh",
    text: `Click vào “Link giới thiệu” và gửi cho bạn bè qua tin nhắn, mạng xã hội đến càng nhiều người  tiếp cận thì cơ hội phát triển mạng lưới càng cao.`,
    img: Images.Step2,
  },
  {
    icon: Step3Icon,
    title: "Tăng thu nhập thụ động",
    text: "Khi người bạn giới thiệu vào hệ thống kinh doanh GSale chốt đơn hàng thành công, bạn cũng sẽ nhận được thu nhập thụ động hấp dẫn theo cơ chế hoa hồng dành cho người giới thiệu.",
    img: Images.Step3,
  },
];

export default function GsaleZone(props) {
  const { isMobile, stories, classes } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.body}>
      {/* <Products list={products} classes={classes} dispatch={dispatch} /> */}
      <Grid container className={classes.block}>
        <Grid item xs={12} sm={6}>
          <img src={Images.Image5} alt="img-5" className="image-5"></img>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="title">
            <span className="color">Với GSale</span> giúp bạn dễ dàng:
            <div className="divider"></div>
          </div>
          {benefits.map((i, index) => (
            <TransText text={i} index={index + 1} />
          ))}
        </Grid>
      </Grid>
      <div className={classes.container}>
        <div className="title">Hướng dẫn cài đặt ứng dụng GSale</div>
        <motion.div key="way-1" {...animateLeft}>
          <div className="row">
            <div className="half">
              <div className="way">Cách 1</div>
              <div>
                Dùng thiết bị di động có kết nối mạng và scan QR Code bên dưới
                để tiến hành cài đặt nhanh.
              </div>
            </div>
            <img src={QrIcon} alt="qr-icon" className="icon" />
          </div>
        </motion.div>
        <motion.div key="way-2" {...animateRight}>
          <div className="row mt-30">
            <div className="half">
              <div className="way">Cách 2</div>
              <div>
                Vào cửa hàng ứng dụng của bạn (CH Play với Android và App Store
                với iOS) tìm kiếm từ khóa [GSale] rồi cài đặt ứng dụng có biểu
                tượng như bên dưới.
              </div>
            </div>
            <img src={GsaleIcon} alt="qr-icon" className="icon" />
          </div>
        </motion.div>
      </div>
      <div className="p-8">
        <Title
          colorText="Phát triển mạng lưới dễ dàng "
          other="Tăng thu nhập thụ động"
        />
        {isMobile ? (
          <Stepper classes={classes} steps={steps} />
        ) : (
          <div className={classes.rowImage}>
            {steps.map((i, idx) => (
              <div className="step">
                <div className="row">
                  <img src={i.icon} alt={`step${idx}`}></img>
                  <div className="text">
                    <div className="bold">{i.title}</div>
                    <div>{i.text}</div>
                  </div>
                </div>
                <img src={i.img} alt={`step-${idx}`} className="img"></img>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={classes.spacing}></div>
      <Stories list={stories} />
      <div className={classes.spacing}></div>
    </div>
  );
}

const TransText = ({ text, index }) => (
  <div key={index} className="row-item">
    <img src={TickIcon} alt="star-icon" className="mr-15"></img>
    <motion.div
      className="left"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.5 }}
      transition={{ duration: 0.2 * index }}
      variants={{
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0 },
      }}
    >
      {text}
    </motion.div>
  </div>
);

const Stepper = ({ classes, steps }) => {
  return (
    <div className={classes.stepper}>
      <Swiper
        slidesPerView={1}
        spaceBetween={40}
        loop={true}
        autoplay={{
          delay: 3000,
        }}
        navigation={{
          nextEl: ".button-next",
          prevEl: ".button-prev",
        }}
        modules={[Autoplay, Navigation]}
        className="mySwiper mx-30"
      >
        {(steps || []).map((i, idx) => (
          <SwiperSlide key={idx}>
            <div className="step">
              <div className="row">
                <img src={i.icon} alt={`step${idx}`}></img>
                <div className="text">
                  <div className="bold">{i.title}</div>
                  <div>{i.text}</div>
                </div>
              </div>
              <img src={i.img} alt={`step-${idx}`} className="img"></img>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="row">
        <img src={PrevIcon} alt="Prev" className="arrow-icon button-prev" />
        <div className="right-icon button-next">
          <img src={RightIcon} alt="Next" style={{ width: 18 }} />
        </div>
      </div>
    </div>
  );
};
