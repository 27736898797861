import bbotoIc from "../assets/icons/icon-bboto.svg";
import bbxmIc from "../assets/icons/icon-bbxm.svg";
import bhngheoIc from "../assets/icons/icon-bhngheo.svg";
import bhnhaIc from "../assets/icons/icon-bhnha.svg";
import bhxhIc from "../assets/icons/icon-bhxh.svg";
import bhytIc from "../assets/icons/icon-bhyt.svg";
import bmcareIc from "../assets/icons/icon-bm-care.svg";
import dotquyIc from "../assets/icons/icon-dotquy.svg";
import ecIc from "../assets/icons/icon-easy-care.svg";
import gcIc from "../assets/icons/icon-gc-care.svg";
import mriderIc from "../assets/icons/icon-master-rider.svg";
import mcxmIc from "../assets/icons/icon-mcxm.svg";
import tainanIc from "../assets/icons/icon-tainan.svg";
import tkasIc from "../assets/icons/icon-tkas.svg";
import tcIc from "../assets/icons/icon-tomato-combo.svg";
import tomatoIc from "../assets/icons/icon-tomato.svg";
import travelIc from "../assets/icons/icon-travel.svg";
import trucotIc from "../assets/icons/icon-trucot.svg";
import votoIc from "../assets/icons/icon-vcoto.svg";
import comboxmIc from "../assets/icons/icon-combo-xm.svg";

const Icons = {
  bbotoIc,
  bbxmIc,
  bhngheoIc,
  bhnhaIc,
  bhxhIc,
  bhytIc,
  dotquyIc,
  ecIc,
  gcIc,
  mcxmIc,
  tainanIc,
  tcIc,
  tomatoIc,
  trucotIc,
  bmcareIc,
  mriderIc,
  tkasIc,
  travelIc,
  votoIc,
  comboxmIc,
};

export default Icons;
