import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import CountUp from "react-countup";
import { Autoplay, EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { banners } from "../../extra/hard-data";
import { animateChild, animateLeft, animateRight } from "../../extra/utils";
import Products from "../components/Products";
import Stories from "../components/Stories";
import Title from "../components/Title";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import AddBtnIcon from "../../assets/icons/add-btn.svg";
import AtmIcon from "../../assets/icons/atm.svg";
import CalendarIcon from "../../assets/icons/calendar.svg";
import MoneyIcon from "../../assets/icons/money.svg";
import StarIcon from "../../assets/icons/star.svg";
import TickIcon from "../../assets/icons/tick.svg";
import UserIcon from "../../assets/icons/user.svg";
import Images from "../../extra/images";

const reason = [
  "Trải nghiệm tất cả các loại hình bảo hiểm từ nhiều công ty bảo hiểm trên thị trường",
  "Thu nhập không giới hạn, nhận thu nhập ngay trong ví",
  "Quy trình thực hiện 100% online, nhanh chóng và tiện lợi",
  "Quản lý hợp đồng mọi lúc mọi nơi",
  "Chính sách thu nhập rõ ràng, minh bạch",
  "Dịch vụ hỗ trợ khách hàng tận tâm",
];

export default function Home(props) {
  const { products, isMobile, stories, date, time, classes, dispatch } = props;
  // const smScreen = useMediaQuery("(max-width:992px)");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.body}>
      <motion.div key="swiper-1" {...animateRight}>
        <div className={classes.swiper}>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            modules={[Autoplay, Pagination, EffectFade]}
            effect={"fade"}
            className="mySwiper"
          >
            {banners.map((banner, index) => (
              <SwiperSlide key={index}>
                <a
                  href={banner.link}
                  target="_bank"
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  <img
                    src={isMobile ? banner.imgMobile : banner.img}
                    alt={`banner-${index}`}
                    className="banner"
                  ></img>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <div className="download">
          Trải nghiệm ngay <ChevronRightIcon />
        </div> */}
        </div>
      </motion.div>
      <motion.div key="img" {...animateLeft}>
        <Grid
          container
          alignItems={"flex-end"}
          className={`${classes.block} ${classes.bgGradient}`}
        >
          <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
            <img src={Images.Image2} alt="img-2" className="image-2" />
          </Grid>
          <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }}>
            <div className="title">
              <span className="color">Tăng thu nhập</span> cùng GSale quá dễ
              <div className="divider"></div>
            </div>
            <motion.div transition={{ duration: 0.5 }} {...animateChild}>
              <div className="row-item">
                <img src={UserIcon} alt="star-icon" className="icon"></img>
                <div className="text">
                  <div className="bold">Đăng ký miễn phí</div>
                  <div>
                    Thủ tục đơn giản, chỉ với 3 phút để trở thành cộng tác viên
                    và bắt đầu hành trình tăng thu nhập của mình.
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              {...animateChild}
              transition={{ duration: 0.7 }}
              variants={{
                hidden: { opacity: 0, x: -100 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              <div className="row-item">
                <img src={MoneyIcon} alt="star-icon" className="icon"></img>
                <div className="text">
                  <div className="bold">Nhận thu nhập</div>
                  <div>
                    Thực hiện các công việc tư vấn bán hàng với các gói bảo hiểm
                    có sẵn thành công, GSale sẽ trả thu nhập thật hấp dẫn vào ví
                    của bạn.
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div transition={{ duration: 0.9 }} {...animateChild}>
              <div className="row-item">
                <img src={AtmIcon} alt="star-icon" className="icon"></img>
                <div className="text">
                  <div className="bold">Rút tiền</div>
                  <div>
                    Bạn hoàn toàn có thể rút tiền về tài khoản ngân hàng ngay
                    sau khi nhận được thu nhập.
                  </div>
                </div>
              </div>
            </motion.div>
          </Grid>
        </Grid>
      </motion.div>
      <div className={classes.spacing}></div>
      <Products list={products || []} dispatch={dispatch} />
      <div className={classes.spacing}></div>
      <Grid container className={`${classes.block} ${classes.bgColor}`}>
        <Grid item xs={12} sm={6}>
          <div className="title">
            <span className="color">Vì sao</span> chọn GSale
            <div className="divider"></div>
          </div>
          {reason.map((i, index) => (
            <TransText text={i} index={index + 1} />
          ))}
          <div className={classes.spacing}></div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={Images.Image3} alt="why-img" className="w-80"></img>
        </Grid>
      </Grid>
      <div className={classes.spacing}></div>
      <Stories list={stories} />
      <Grid container alignItems={"flex-end"} className={classes.block}>
        <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
          <div style={{ position: "relative" }}>
            <img src={Images.Image4a} alt="img-4" className="image-2"></img>
            <div className={classes.timeBox}>
              <div className="label">Thành viên đăng kí</div>
              <div className="row-line">
                <div className="row-line">
                  <img src={CalendarIcon} alt="cad-icon" className="icon-5" />
                  <div>{date}</div>
                </div>
                <div className="bg-color">{time}</div>
              </div>
              <div className="row-line">
                <div className="bold">650.000+ người</div>
                <div className="row-line">
                  <img src={Images.AvtGroup} alt="avt-group" className="img" />
                  <img src={AddBtnIcon} alt="add-btn" />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }} className="summary">
          <div className="title">
            <span className="color">Tham gia GSale</span> ngay hôm nay
            <div className="divider"></div>
          </div>
          <div className="row">
            <div className="row-number">
              <img src={StarIcon} alt="star-icon" className="mr-15"></img>
              <CountUp
                end={650000 || 0}
                duration={1.5}
                separator="."
                enableScrollSpy={true}
              />
              +
            </div>
            <div className="left w-80">Thành viên đăng ký</div>
          </div>
          <div className="row">
            <div className="row-number">
              <img src={StarIcon} alt="star-icon" className="mr-15"></img>
              <span>1.5 Triệu+</span>
            </div>
            <div className="left w-80">Sản phẩm bán ra mỗi tháng</div>
          </div>
          <div className="row">
            <div className="row-number">
              <img src={StarIcon} alt="star-icon" className="mr-15"></img>
              <CountUp
                end={15 || 0}
                duration={2.5}
                separator="."
                enableScrollSpy={true}
              />
              +
            </div>
            <div className="left w-80">
              Hợp tác với hơn 15 Nhà Bảo hiểm hàng đầu
            </div>
          </div>
          <div className="row">
            <div className="row-number">
              <img src={StarIcon} alt="star-icon" className="mr-15"></img>
              <CountUp
                end={15 || 0}
                duration={2.5}
                separator="."
                enableScrollSpy={true}
              />
              <span className="ml-8">Triệu+</span>
            </div>
            <div className="left w-80">
              {" "}
              Thu nhập trung bình mỗi tháng của người dùng
            </div>
          </div>
        </Grid>
      </Grid>
      <Partner isMobile={isMobile} classes={classes} />
      {/* <News list={[]} /> */}
    </div>
  );
}

const Partner = ({ isMobile, classes }) => {
  const slidesPerView = isMobile ? 2 : 6;
  const slides = [
    Images.BhtcPartner,
    Images.LianPartner,
    Images.PtiPartner,
    Images.PviPartner,
    Images.HVPartner,
    Images.GrabPartner,
    Images.MicPartner,
    Images.ShopbackPartner,
    Images.AhamovePartner,
    Images.BVPartner,
    Images.EPassPartner,
    Images.OneLinicPartner,
    Images.TgddPartner,
    Images.VniPartner,
    Images.VacPartner,
  ];

  return (
    <div className={classes.partner}>
      <Title colorText="Đối tác" text="của GSale" />
      <div className="relative">
        <Swiper
          slidesPerView={slidesPerView}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          pagination={{
            dynamicBullets: true,
          }}
          className="mySwiper"
        >
          {slides.map((img, index) => (
            <SwiperSlide key={index}>
              <img src={img} alt={img}></img>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

const TransText = ({ text, index }) => (
  <div key={index} className="row-item bg-white">
    <img src={TickIcon} alt="star-icon" className="mr-15"></img>
    <motion.div
      className="left"
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.5 }}
      transition={{ duration: 0.2 * index }}
      variants={{
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0 },
      }}
    >
      {text}
    </motion.div>
  </div>
);
